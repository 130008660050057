<template>
<div id="sidebar-demo">
    <div class="vx-row">
        <div class="vx-col xl:w-1/2 w-full m-0 cursor">
        </div>
    </div>
</div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
    data() {
        return {
        }
    },
    components: {
        StatisticsCardLine,
        VueApexCharts,
    },
    async created() {
    },
    methods: {
    }
}
</script>
